import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ title, children, onClose }) => {
    console.log("Rendering Modal with title:", title); // Для отладки

    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
            }}
        >
            <Box
                style={{
                    background: "#1e1e1e",
                    padding: "20px",
                    borderRadius: "10px",
                    width: "400px",
                    position: "relative",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                }}
            >
                <IconButton
                    style={{ position: "absolute", top: "10px", right: "10px", color: "white" }}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
                <Typography
                    variant="h6"
                    style={{
                        color: "white",
                        marginBottom: "20px",
                        textAlign: "center",
                    }}
                >
                    {title}
                </Typography>
                {children}
            </Box>
        </div>
    );
};

export default Modal;
