import axios from "axios";

export const checkAuth = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
        return false;
    }

    try {
        const res = await axios.get("https://asiccontrol.net/api/check-token/", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return res.status === 200;
    } catch (err) {
        console.error("Token invalid or expired:", err.response?.data || err);
        return false;
    }
};
