import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ParticlesBackground from "./ParticlesBackground";
import Header from "./Header";
import Modal from "./Modal";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import Dashboard from "./Dashboard";
import MyDevices from "./MyDevices";
import { checkAuth } from "./utils/auth"; // Импорт функции проверки авторизации

const App = () => {
    //const navigate = useNavigate(); // Получаем функцию navigate

    const [isAuthenticated, setIsAuthenticated] = useState(false); // Статус авторизации
    const [modalType, setModalType] = useState(null);

    // Проверка токена при загрузке приложения
    useEffect(() => {
        const verifyAuth = async () => {
            const isAuth = await checkAuth();
            setIsAuthenticated(isAuth); // Обновляем статус авторизации
        };

        verifyAuth();
    }, []);

    const openModal = (type) => setModalType(type);
    const closeModal = () => setModalType(null);

    const handleLogin = () => {
        setIsAuthenticated(true);
        closeModal();
    };

    const handleRegister = () => {
        setIsAuthenticated(true);
        closeModal();
    };

    const handleLogout = (navigate) => {
        setIsAuthenticated(false);
        localStorage.removeItem("token"); // Удаляем токены при выходе
        localStorage.removeItem("refresh_token");
        navigate("/"); // Перенаправление на главную страницу
    };

    const darkTheme = createTheme({
        palette: {
            mode: "dark",
            primary: {
                main: "#90caf9",
            },
            secondary: {
                main: "#f48fb1",
            },
        },
        typography: {
            fontFamily: "Roboto, Arial, sans-serif",
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <ParticlesBackground />
            <Router>
                <Header
                    isAuthenticated={isAuthenticated}
                    onLoginClick={() => openModal("login")}
                    onRegisterClick={() => openModal("register")}
                    onLogout={(navigate) => handleLogout(navigate)}
                />
                <Routes>
                    {/* Главная страница */}
                    <Route
                        path="/"
                        element={
                            !isAuthenticated ? (
                                <main
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "80vh",
                                        color: "white",
                                    }}
                                >
                                    <div
                                        style={{
                                            background: "rgba(18, 18, 18, 0.8)",
                                            padding: "20px",
                                            borderRadius: "10px",
                                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                                            textAlign: "center",
                                        }}
                                    >
                                        <h1>Welcome to ASIC Control</h1>
                                        <p>Manage and monitor your ASIC devices with ease and security.</p>
                                    </div>
                                </main>
                            ) : (
                                <Navigate to="/dashboard" />
                            )
                        }
                    />
                    {/* Страница регистрации */}
                    <Route
                        path="/register"
                        element={
                            !isAuthenticated ? (
                                <RegisterForm onRegister={handleRegister} />
                            ) : (
                                <Navigate to="/dashboard" replace />
                            )
                        }
                    />
                    {/* Страница дашборда */}
                    <Route
                        path="/dashboard"
                        element={
                            isAuthenticated ? (
                                <Dashboard />
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />
                    {/* Страница "Мои устройства" */}
                    <Route
                        path="/my-devices"
                        element={
                            isAuthenticated ? (
                                <MyDevices />
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />
                </Routes>
                {/* Модальные окна для входа и регистрации */}
                {modalType === "login" && (
                    <Modal title="Login" onClose={closeModal}>
                        <LoginForm onLogin={handleLogin} />
                    </Modal>
                )}
                {modalType === "register" && (
                    <Modal title="Register" onClose={closeModal}>
                        <RegisterForm onRegister={handleRegister} />
                    </Modal>
                )}
            </Router>
        </ThemeProvider>
    );
};

export default App;
