import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Menu, MenuItem } from "@mui/material";
import "./Header.css";

const Header = ({ isAuthenticated, onLoginClick, onRegisterClick, onLogout }) => {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <header className="header">
            <div className="logo">
                <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                    AsicControl
                </Link>
            </div>
            {isAuthenticated ? (
                <>
                    {/* Мобильное меню для авторизованных пользователей */}
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleMenuOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        className="mobile-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem
                            component={Link}
                            to="/profile"
                            onClick={handleMenuClose}
                        >
                            Профиль
                        </MenuItem>
                        <MenuItem
                            component={Link}
                            to="/my-devices"
                            onClick={handleMenuClose}
                        >
                            Мои устройства
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleMenuClose();
                                onLogout();
                            }}
                        >
                            Выход
                        </MenuItem>
                    </Menu>
                </>
            ) : (
                <div className="actions">
                    {/* Кнопки для входа и регистрации */}
                    <button
                        className="header-btn"
                        onClick={onLoginClick}
                    >
                        Login
                    </button>
                    <button
                        className="header-btn"
                        onClick={onRegisterClick}
                    >
                        Register
                    </button>
                </div>
            )}
        </header>
    );
};

export default Header;
