import React, { useState, useEffect } from "react";
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";

const MyDevices = () => {
    const [devices, setDevices] = useState([]);
    const [form, setForm] = useState({
        device_model: "", // Соответствует модели Django
        worker_name: "",
        internal_ip: "",
        pool_address: "",
        pool_password: "",
    });
    const [editingIndex, setEditingIndex] = useState(null); // Для редактирования
    const [manufacturers, setManufacturers] = useState({}); // Для хранения данных производителей и моделей
    const [openModal, setOpenModal] = useState(false); // Для модального окна
    const [vpnCertificate, setVpnCertificate] = useState(""); // Содержимое реального сертификата
    const [loadingCertificate, setLoadingCertificate] = useState(false); // Для отображения загрузки

    // Загружаем данные устройств пользователя и производителей
    useEffect(() => {
        axios
            .get("https://asiccontrol.net/user-devices/", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((response) => setDevices(response.data))
            .catch((error) =>
                console.error("Ошибка загрузки устройств пользователя:", error)
            );

        axios
            .get("https://asiccontrol.net/asic-devices/")
            .then((response) => {
                const grouped = response.data.reduce((acc, device) => {
                    acc[device.manufacturer] = acc[device.manufacturer] || [];
                    acc[device.manufacturer].push({ id: device.id, model: device.model });
                    return acc;
                }, {});
                setManufacturers(grouped);
            })
            .catch((error) =>
                console.error("Ошибка загрузки данных производителей:", error)
            );
    }, []);

    // Обновление данных формы
    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    // Проверка на заполненность обязательных полей
    const isValidForm = () => {
        return form.device_model && form.worker_name && form.internal_ip && form.pool_address;
    };

    // Добавление или редактирование устройства
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValidForm()) {
            alert("Пожалуйста, заполните все обязательные поля.");
            return;
        }

        // Автоматическое добавление "http://" к pool_address, если отсутствует
        if (
            form.pool_address &&
            !form.pool_address.startsWith("http://") &&
            !form.pool_address.startsWith("https://")
        ) {
            form.pool_address = `http://${form.pool_address}`;
        }

        try {
            if (editingIndex !== null) {
                const response = await axios.put(
                    `https://asiccontrol.net/user-devices/${devices[editingIndex].id}/`,
                    form,
                    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
                );
                const updatedDevices = [...devices];
                updatedDevices[editingIndex] = response.data;
                setDevices(updatedDevices);
                setEditingIndex(null);
            } else {
                const response = await axios.post("https://asiccontrol.net/user-devices/", form, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                });
                setDevices([...devices, response.data]);
            }
        } catch (error) {
            console.error("Ошибка при сохранении устройства:", error.response || error);
            alert("Не удалось сохранить устройство.");
        }

        setForm({
            device_model: "",
            worker_name: "",
            internal_ip: "",
            pool_address: "",
            pool_password: "",
        });
    };

    // Удаление устройства
    const handleDelete = async (index) => {
        if (window.confirm("Вы уверены, что хотите удалить это устройство?")) {
            try {
                const deviceId = devices[index].id;
                await axios.delete(`https://asiccontrol.net/user-devices/${deviceId}/`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                });
                setDevices(devices.filter((_, i) => i !== index));
            } catch (error) {
                console.error("Ошибка при удалении устройства:", error.response || error);
                alert("Не удалось удалить устройство.");
            }
        }
    };

    // Обновление формы при редактировании
    const handleEdit = (index) => {
        const device = devices[index];
        setForm({
            device_model: device.device_model.id, // Используем ID модели
            worker_name: device.worker_name,
            internal_ip: device.internal_ip,
            pool_address: device.pool_address,
            pool_password: device.pool_password || "",
        });
        setEditingIndex(index);
    };

    // Генерация реального сертификата VPN
    const generateCertificate = async () => {
        setLoadingCertificate(true);
        try {
            const response = await axios.post(
                "https://asiccontrol.net/api/generate-certificate/",
                {},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );
            setVpnCertificate(response.data.client_ovpn_path);
            setOpenModal(true);
        } catch (error) {
            console.error("Ошибка при генерации сертификата:", error.response || error);
            alert("Не удалось сгенерировать сертификат.");
        } finally {
            setLoadingCertificate(false);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(vpnCertificate);
        alert("Сертификат скопирован в буфер обмена.");
    };

    return (
        <div style={{ padding: "20px" }}>
            <h1 style={{ textAlign: "center" }}>Мои устройства</h1>
            <form onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Производитель</InputLabel>
                    <Select
                        value={
                            Object.entries(manufacturers).find(([key, models]) =>
                                models.some((m) => m.id === form.device_model)
                            )?.[0] || ""
                        }
                        onChange={(e) =>
                            setForm({
                                ...form,
                                device_model: "",
                                manufacturer: e.target.value,
                            })
                        }
                    >
                        {Object.keys(manufacturers).map((manufacturer) => (
                            <MenuItem key={manufacturer} value={manufacturer}>
                                {manufacturer}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {form.manufacturer && (
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Модель</InputLabel>
                        <Select
                            value={form.device_model}
                            onChange={(e) =>
                                setForm({ ...form, device_model: e.target.value })
                            }
                        >
                            {manufacturers[form.manufacturer]?.map((device) => (
                                <MenuItem key={device.id} value={device.id}>
                                    {device.model}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                <TextField
                    fullWidth
                    label="Имя воркера"
                    name="worker_name"
                    value={form.worker_name}
                    onChange={handleChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Внутренний IP"
                    name="internal_ip"
                    value={form.internal_ip}
                    onChange={handleChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Адрес пула"
                    name="pool_address"
                    value={form.pool_address}
                    onChange={handleChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Пароль пула (если есть)"
                    name="pool_password"
                    value={form.pool_password}
                    onChange={handleChange}
                    margin="normal"
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "20px" }}
                >
                    {editingIndex !== null ? "Сохранить изменения" : "Добавить устройство"}
                </Button>
            </form>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Имя воркера</TableCell>
                            <TableCell>Внутренний IP</TableCell>
                            <TableCell>Адрес пула</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {devices.map((device, index) => (
                            <TableRow key={index}>
                                <TableCell>{device.worker_name}</TableCell>
                                <TableCell>{device.internal_ip}</TableCell>
                                <TableCell>{device.pool_address}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEdit(index)} color="primary">
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(index)} color="secondary">
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Button
                variant="contained"
                color="secondary"
                onClick={generateCertificate}
                style={{ marginTop: "20px" }}
                disabled={loadingCertificate}
            >
                {loadingCertificate ? "Генерация..." : "Сформировать сертификат"}
            </Button>

            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                <DialogTitle>Ваш VPN Сертификат</DialogTitle>
                <DialogContent>
                    <pre style={{ whiteSpace: "pre-wrap" }}>{vpnCertificate}</pre>
                </DialogContent>
                <DialogActions>
                    <Button onClick={copyToClipboard} color="primary">
                        Скопировать сертификат
                    </Button>
                    <Button onClick={() => setOpenModal(false)} color="secondary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MyDevices;
