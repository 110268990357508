import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoginForm = ({ onLogin }) => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const [error, setError] = useState(null);

    const { email, password } = formData;

    const navigate = useNavigate(); // Для перенаправления на дашборд

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post("https://asiccontrol.net/api/login/", {
                email,
                password,
            });

            // Сохраняем токен (если сервер возвращает его)
            localStorage.setItem("token", res.data.access); // Сохраняем access-токен
            localStorage.setItem("refresh_token", res.data.refresh); // Сохраняем refresh-токен

            // Обновляем состояние авторизации
            onLogin();

            // Перенаправляем на дашборд
            navigate("/dashboard");
        } catch (err) {
            console.error(err.response?.data || "An error occurred");
            setError(err.response?.data?.detail || "Login failed.");
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <Box
                display="flex"
                flexDirection="column"
                gap="15px"
                alignItems="center"
                width="100%"
            >
                <Typography variant="h6" style={{ color: "white" }}>
                    Login to your account
                </Typography>
                {error && (
                    <Typography
                        variant="body2"
                        style={{ color: "red", textAlign: "center" }}
                    >
                        {error}
                    </Typography>
                )}
                <TextField
                    label="Email"
                    name="email"
                    value={email}
                    onChange={onChange}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                    }}
                    style={{ background: "#2e2e2e" }}
                    required
                />
                <TextField
                    label="Password"
                    name="password"
                    value={password}
                    onChange={onChange}
                    type="password"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                    }}
                    style={{ background: "#2e2e2e" }}
                    required
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: "15px" }}
                >
                    Login
                </Button>
            </Box>
        </form>
    );
};

export default LoginForm;
