import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const RegisterForm = ({ onRegister }) => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        password_confirm: "",
    });

    const [error, setError] = useState(null);

    const { email, password, password_confirm } = formData;

    const navigate = useNavigate();

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (password !== password_confirm) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const res = await axios.post("https://asiccontrol.net/api/register/", {
                email,
                password1: password,
                password2: password_confirm,
            });

            // Проверяем наличие токенов в ответе API
            if (res.data.access && res.data.refresh) {
                // Сохраняем токены в localStorage
                localStorage.setItem("token", res.data.access);
                localStorage.setItem("refresh_token", res.data.refresh);

                // Обновляем состояние авторизации
                onRegister();

                // Перенаправляем на дашборд
                navigate("/dashboard");
            } else {
                throw new Error("Токен не возвращен в ответе API.");
            }
        } catch (err) {
            console.error(err.response?.data || "An error occurred");
            setError(err.response?.data?.detail || "Registration failed.");
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <Box
                display="flex"
                flexDirection="column"
                gap="15px"
                alignItems="center"
                width="100%"
            >
                <Typography variant="h6" style={{ color: "white" }}>
                    Register a new account
                </Typography>
                {error && (
                    <Typography
                        variant="body2"
                        style={{ color: "red", textAlign: "center" }}
                    >
                        {error}
                    </Typography>
                )}
                <TextField
                    label="Email"
                    name="email"
                    value={email}
                    onChange={onChange}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                    }}
                    style={{ background: "#2e2e2e" }}
                    required
                />
                <TextField
                    label="Password"
                    name="password"
                    value={password}
                    onChange={onChange}
                    type="password"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                    }}
                    style={{ background: "#2e2e2e" }}
                    required
                />
                <TextField
                    label="Confirm Password"
                    name="password_confirm"
                    value={password_confirm}
                    onChange={onChange}
                    type="password"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                    }}
                    style={{ background: "#2e2e2e" }}
                    required
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: "15px" }}
                >
                    Register
                </Button>
            </Box>
        </form>
    );
};

export default RegisterForm;
